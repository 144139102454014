
import { mapState } from 'vuex'

import CKEditor from '@/components/CKEditor.vue'
import { CREATE_EXCURSION_REQUEST, UPDATE_EXCURSION_REQUEST } from '@/store/actions/excursion.ts'

import { urlencode } from '@/utils/index'

export default {
    name: 'ActivityAdmin',
    data: function() {
        return {
            date_range: null,
            mounted: false,
            name_approved: true,
            max_subscribers_approved: true,
            period_approved: true,
        }
    },
    props: {
        excursion: Object,
    },
    components: {
        CKEditor
    },
    methods: {
        updateName: function(new_value) {
            let name_input = this.$refs['name-input']
            let name_help = this.$refs['excursion-name-help']
            if (/_/.test(new_value)) {
                name_help.innerHTML = 'Naam van de excursie mag geen \'_\' bevatten'
                name_input.classList.add('is-invalid')
                this.name_approved = false
                return
            } else if (this.excursion_names.includes(new_value)) {
                name_help.innerHTML = 'De naam van de excursie is niet uniek, kies een andere naam'
                name_input.classList.add('is-invalid')
                this.name_approved = false
                return
            }
            this.excursion.name = new_value  // The value setting
            name_input.classList.remove('is-invalid')
            name_help.innerHTML = ''
            this.name_approved = true
        },
        updateMaxSubscribers: function(new_value) {
            if (new_value > 0) {
                let name_input = this.$refs['max-subscribers-input']
                let name_help = this.$refs['max-subscribers-help']
                this.excursion.max_subscribers = new_value  // The value setting
                name_help.innerHTML = ''
                name_input.classList.remove('is-invalid')
                this.max_subscribers_approved = true
            } else {
                this.max_subscribers_approved = false
            }
        },
        updatePeriod: function(new_value) {
            if (this.isKeuzedeel) {
                let name_input = this.$refs['period-input']
                let name_help = this.$refs['period-help']
                if (new_value > 0 && new_value <= 4) {
                    this.excursion.period = new_value  // The value setting
                    name_help.innerHTML = ''
                    name_input.classList.remove('is-invalid')
                    this.period_approved = true
                } else {
                    name_help.innerHTML = 'Kies een periode tussen 1 en 4'
                    name_input.classList.add('is-invalid')
                    this.period_approved = false
                }
            }
        },
        handleOk(bvModalEvt) {
            // Prevent submitting by default
            bvModalEvt.preventDefault()
            this.submit()
        },
        submit: async function() {
            if (!(this.validateForm())) return

            if (!('_id' in this.excursion)) {
                await this.create()
            } else {
                await this.update()
            }

            // Eventually close the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('excursion-admin-modal')
            })
        },
        validateForm() {
            if (!('name' in this.excursion)) {
                let name_input = this.$refs['name-input']
                let name_help = this.$refs['excursion-name-help']
                name_help.innerHTML = 'Naam is een verplicht veld'
                name_input.classList.add('is-invalid')
                this.name_approved = false
            }

            if (!('max_subscribers' in this.excursion)) {
                let name_input = this.$refs['max-subscribers-input']
                let name_help = this.$refs['max-subscribers-help']
                name_help.innerHTML = 'Max. aantal inschrijvingen is een verplicht veld'
                name_input.classList.add('is-invalid')
                this.max_subscribers_approved = false
            }

            if (this.isKeuzedeel && !('period' in this.excursion)) {
                let name_input = this.$refs['period-input']
                let name_help = this.$refs['period-help']
                name_help.innerHTML = 'Kies een periode tussen 1 en 4'
                name_input.classList.add('is-invalid')
                this.period_approved = false
            }
            let approved = this.name_approved && this.max_subscribers_approved &&
                (!(this.isKeuzedeel) || this.period_approved)
            return approved
        },
        create() {
            this.$store.dispatch(CREATE_EXCURSION_REQUEST, this.excursion).then()

            if (this.excursion.type === 'reis') {

                // TODO: containment
                let scope = (this.excursion.scope === 'europa') ?
                    'buitenlandse-reizen': this.excursion.scope

                //let reis_url = '/activiteiten/' + this.excursion.scope + '/' + urlencode(this.excursion.name)
                let reis_url = '/activiteiten/' + scope + '/' + urlencode(this.excursion.name)
                this.$router.push(reis_url)
            }
        },
        update() {
            if (!(this.validateForm())) return
            this.excursion.id = this.excursion._id
            this.$store.dispatch(UPDATE_EXCURSION_REQUEST, this.excursion).then()
        },

        reversDayMonth: function(date) {
            // Datestring must be simular like: 01/04/2020
            var _date_array = date.match(/(.*)\/([^/]+)\/(.*)/)
            var date_array = _date_array.slice(1).map(function(x) {
                let el = Number.parseInt(x)
                return el
                /*
                if (el > 9) return el
                return '0' + el
                */
            })
            var [a,b,c] = date_array
            return b + '-' + a + '-' + c
        },
        _stringifyDate: function(date) {
            let _date = date.toLocaleDateString()
            //
            if (_date.match(/\d*-\d*-\d{4}/) !== null) return _date
            return this.reversDayMonth(_date)
        },
        stringifyDate(date_object) {
            if ('start' in date_object) {
                let start = this._stringifyDate(date_object.start)
                let end = this._stringifyDate(date_object.end)

                if (start == end) {
                    return start
                } else {
                    return start + ' - ' + end
                }
            } else {
                return this._stringifyDate(date_object)
            }
        },
    },
    mounted: function() {
        this.mounted = true
    },
    computed: {
        ...mapState({
            excursion_names: (state: any) => state.excursion.excursions.map((x) => x.name)
        }),
        isReis: function() {
            return this.excursion.type === 'reis'
        },
        isKeuzedeel: function() {
            return this.excursion.type === 'keuzedeel'
        },
        isNew: function() {
            return (!('_id' in this.excursion))
        },
    },
    watch: {
        date_range: function(newValue) {
            this.excursion.datetime = this.stringifyDate(newValue)
        },
    }
}
