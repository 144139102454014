
import { parseDate } from '@/utils/index.ts'

export default {
    name: 'ShowIDPopUp',
    props: {
        id: {type: String, default: 'ShowIDPopUp-modal'},
        subscription: {}
    },
    methods: {
        parseDate(date) {
            return parseDate(date)
        },
        closeModal() {
            this.$bvModal.hide(this.id)
            this.$emit('close')
        },
    },
}
