
import { mapState } from 'vuex'
import SubscriptionsTable from '@/components/subscriptions/SubscriptionsTable.vue'

export default{
    name: 'ExcursionSubscriptions',
    components: {
        SubscriptionsTable,
    },
    data() {
        return {
            mounted: false,
            table_fields: [
                {
                    key: 'number',
                    label: 'Student nummer',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Voornaam',
                    sortable: true
                },
                {
                    key: 'last_name',
                    label: 'Achternaam',
                    sortable: true
                },
                {
                    key: 'preference',
                    label: 'Keuze',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    sortable: true
                },
            ],
            detail_fields: [
                {
                    key: 'grade',
                    label: 'Klas',
                },
                {
                    key: 'email',
                    label: 'e-mail'
                },
                {
                    key: 'phone',
                    label: 'Telefoonnummer'
                },
            ],
        }
    },
    props: {
        excursion: {},
        scope: {type: String},
    },
    mounted() {
        this.mounted = true
    },
    computed: {
        ...mapState({
            profiles_object: (state: any) => state.user.profiles_object
        }),
        getDetailFields() {
            return this.detail_fields
            // If payed info is added to the details use the following code:
            /*
            if (!(this.mounted)) return
            let students_costs = this.excursion.costs_students
            if (typeof(students_costs) === 'number' && students_costs > 0) {
                return this.detail_fields
            } else {
                return this.detail_fields.slice(0, -1)
            }
            */
        },
        tableFields() {
            if (this.scope === 'keuzedeel') {
                return this.table_fields
            } else {
                return this.table_fields.filter(it => !(it.key === 'preference'))
            }
        },
        getSubscriptions() {
            if (!(this.mounted)) return
            if (!('subscriptions' in this.excursion)) return
            if (this.excursion.subscriptions === null || this.excursion.subscriptions.length === 0) {
                return 'Er zijn (nog) geen inschrijvingen.'
            }
            return this.excursion.subscriptions.map(sub => {
                try {
                    let subscription_profile = {
                        ...this.profiles_object[sub.student_id],
                        ...sub
                    }

                    // Add excursion type to see whether to show ID in table
                    subscription_profile.type = this.excursion.type

                    // Just to make sure, the _id of the object must be the subscription _id!
                    subscription_profile._id = sub._id
                    return subscription_profile
                } catch (err) {
                    return {'first_name': 'error', 'last_name_prefix': err}
                }
            })
        }
    },
}
