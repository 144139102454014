
import { mapGetters } from 'vuex'
import { PAYMENT_REQUEST } from '@/store/actions/subscription.ts'
import { UNSUBSCRIBE_REQUEST } from '@/store/actions/subscription.ts'
import youSure from '@/utils/ConfirmationModal.ts'
import ShowIDPopUp from '@/components/account/ShowIDPopUp.vue'

export default{
    name: 'SubscriptionsTable',
    components: {
        ShowIDPopUp
    },
    data() {
        return {
            filter: '',
            ID_modal_id: 'ShowIDPopUp-modal-' + Math.random().toString(36).substring(2, 15),
            subscription: {},
        }
    },
    props: {
        subscriptions: {type: Array },
        fields: {type: Array },
        detail_fields: {type: Array },
    },
    methods: {
        parseDate(timestamp) {
            var date = new Date(timestamp)
            return date.toLocaleDateString()
        },
        togglePayed(subscription) {
            let payment = { id: subscription._id, payed: !subscription.payed }
            subscription.payed = 'in-progress'
            this.$store.dispatch(PAYMENT_REQUEST, payment)
        },
        isFree(price) {
            if (typeof(price) === 'number' && price > 0) {
                return false
            }
            return true
        },
        getPrice: function(value) {
            if (this.isFree(value)) return 'Gratis'
            return '€' + value
        },
        getPayed: function(payed) {
            return (payed) ? '&#9989;' : '&#10060;'
        },
        unsubscribe(subscription) {
            this.$set(subscription, 'deleting', true)
            let you_sure_msg = (subscription.payed) ?
                'Zeker dat je de inschrijving wilt verwijderen?<br />De inschrijving is al betaald!' :
                'Zeker dat je de inschrijving wilt verwijderen?'
            youSure(this, you_sure_msg)
                .then((res) => {
                    if (res) this.$store.dispatch(UNSUBSCRIBE_REQUEST, subscription)
                    else subscription['deleting'] = false
                })
                .catch((err) => {
                    alert('Uitschrijven mislukt:\n' + err)
                    subscription['deleting'] = false
                })
        },
        showIDModal(subscription) {
            this.subscription = subscription
            this.$bvModal.show(this.ID_modal_id)
        }
    },
    computed: {
        ...mapGetters(['isAdmin']),
    },
    updated() {
        let items = this.$refs['subscriptionsTable']
        this.$emit('active_items', items.computedItems)
    },
}
